import React from 'react';
import { Status } from '../../Context/StatusContext';
import './StatusCard.css';

const StatusCard = ({ projectName, status, logo, onClick }) => {
  const [statusText, statusClass] = (() => {
    switch (status) {
      case Status.Operational: return ["Operational", "text-operational"];
      case Status.Interruption: return ["Interruption", "text-interruption"];
      case Status.ComingSoon: return ["Coming Soon...", "text-operational"];
      default: return ["", ""]; // here to appease linting - using the enum this will never hit
    }
  })();

  // TODO: this design has some minor issues with super narrow screens and/or long project names
  return (
    // Using a button means we have to do some work to get rid of default styling,
    // but we get the benefits of instant keyboard navigability and accessibility
    <button className="status-card w-100 text-start" onClick={onClick} disabled={!onClick}>
      <div className="py-2 align-self-center">
        <p className="fw-semibold text-project-name lh-1 mb-1">{projectName}</p>
        <p className={`fw-semibold ${statusClass}`} style={{ fontSize: "18px", lineHeight: "25px" }}>{statusText}</p>
      </div>
      <div className="status-card-icon">
        <img src={logo} alt={`${projectName} logo`} />
      </div>
    </button >
  );
};

export default StatusCard;